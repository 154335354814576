var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-card',{attrs:{"title":"签约金额比例（元）"}},[_c('div',[_c('vue-element-loading',{attrs:{"active":_vm.loading,"color":"#00cca2","spinner":"spinner"}}),_c('ve-ring',{attrs:{"legend":{show:false},"judge-width":"","height":"360px","title":{
          text: _vm.item.title,
          subtext: '总数（元）',
          textStyle: {
              fontSize: 22,
              color: ['#23d9ae']
          },
          subtextStyle: {
              fontSize: 15
          },
          x: 'center',
          y: '50%'
      },"data":_vm.item,"extend":{
          series:{
              label: {
                  normal: {
                      formatter: function (params) {
                          return (
                               ((params.name) + " " + (params.value) + " (" + (params.percent) + "%)")
                          );
                      }
                  }
              }
          }
      }}}),(_vm.first>0 && _vm.item.rows.length == 0)?_c('LEmpty'):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }