<template>
  <a-card title="签约金额比例（元）">
      <div>
        <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
        <ve-ring
        :legend="{show:false}"
        judge-width
        height="360px"
        :title="{
            text: item.title,
            subtext: '总数（元）',
            textStyle: {
                fontSize: 22,
                color: ['#23d9ae']
            },
            subtextStyle: {
                fontSize: 15
            },
            x: 'center',
            y: '50%'
        }"
        :data="item" :extend="{
            series:{
                label: {
                    normal: {
                        formatter: params => {
                            return (
                                 `${params.name} ${params.value} (${params.percent}%)`
                            );
                        }
                    }
                }
            }
        }" ></ve-ring>
        <LEmpty v-if="first>0 && item.rows.length == 0"/>
    </div>
  </a-card>
</template>

<script>
export default {
  name: 'veRinngR',
  props:['item','loading'],
  data(){
      return{
          first:0
      }
  },
  watch: {
      loading(newValue, oldValue) {
          this.first++;
      }
  },
}
</script>
